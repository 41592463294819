import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { from } from 'rxjs';
import { env } from 'process';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getDashboardData() {
    return this.http.get(environment.apiPath + "/api/getUserDashboardData", { withCredentials: true });
  }

  setUserPreferneces(data) {
    return this.http.post(environment.apiPath + "/api/setUserDashboardPreference", data, { withCredentials: true });
  }

  //key indicator api's
  getQuoteGeneratedData(custId,dateFilter) {
    return this.http.get(environment.apiPath + '/api/get/customer/QUOTES_GENERATED?customerId=' + custId +'&dateFilter='+dateFilter, { withCredentials: true })
  }
  getInvoiceCreatedData(custId,dateFilter){
    return this.http.get(environment.apiPath+'/api/get/customer/INVOICE_CREATED?customerId='+custId+'&dateFilter='+dateFilter,{withCredentials:true})
  }
  getShipmentData(custId,dateFilter){
    return this.http.get(environment.apiPath+'/api/get/customer/SHIPMENTS?customerId='+custId+'&dateFilter='+dateFilter,{withCredentials:true})
  }




  getSelectedGraphList(custId, userid) {
    return this.http.get(environment.apiPath + '/api/sc/wizards/get?customerId=' + custId + '&userId=' + userid, { withCredentials: true })
  }
  updateSelectedGraphList(data) {
    return this.http.post(environment.apiPath + '/api/sc/wizard/save', data, { withCredentials: true });
  }




  getShipmentPerMonth(fromDate,toDate) {
    // return this.http.get(environment.apiPath + '/api/customer/shipment/getCount?fromDate=2021-01-01&toDate=2023-05-18' , { withCredentials: true })
    return this.http.get(environment.apiPath + '/api/customer/shipment/getCount?fromDate='+fromDate+'&toDate='+toDate, { withCredentials: true })
  }


  getCourierAndLTLPerMonth(fromDate,toDate) {
    return this.http.get(environment.apiPath + '/api/shipments/shipmentMode/getCount?fromDate='+fromDate+'&toDate='+toDate, { withCredentials: true })
  }

  getQuotationGraph(fromDate,toDate){
    // return this.http.get(environment.apiPath+'/api/customer/quote/getCount?fromDate=2021-01-01&toDate=2023-05-18',{withCredentials:true})
    return this.http.get(environment.apiPath+'/api/customer/quote/getCount?fromDate='+fromDate+'&toDate='+toDate,{withCredentials:true})
  }

  getTopCarrierGraph(custId,fromDate,toDate){
    return this.http.get(environment.apiPath+'/api/top/carrierList?customerId='+custId+'&fromDate='+fromDate+'&toDate='+toDate,{withCredentials:true})
  }
  getAllShipmentGraph(custId,fromDate,toDate, type){
    return this.http.get(environment.apiPath+'/api/customer/shipment/byCountry?customerId='+custId+'&fromDate='+fromDate+'&toDate='+toDate + '&metricType='+type,{withCredentials:true})
  }
}
