<div class="m-0" id="printable">
    <div class="col-12">
        <app-spinner></app-spinner>
        <div class="d-flex justify-content-between pt-3 mb-3">
            <div>
                <div class="d-flex">
                    <span class="icons mr-2 cursor_pointer"><img src="assets/img/shipment/fi-rr-print.svg" alt=""
                            (click)="printPDFs()" /></span>
                    <div class="mr-2 text-start h_35">
                        <a style="
                padding: 5px;
                border: 2px solid #d4d4d4;
                border-radius: 5px;
                opacity: 0.7;
                cursor: pointer;
              "><img src="/assets/img/shipment/fi-rr-download.svg" alt="" (click)="downloadPDF()" /></a>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end h_15">
                <img class="cursor_pointer" src="assets/img/Currier-Selection/close-x.svg" alt=""
                    (click)="closeModal()" />
            </div>
        </div>
        <div id="pdfContainer" #pdfContainer></div>
    </div>
</div>