import { Component, OnInit,Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DocumentService } from './../../../services/document.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { LoadingService } from 'src/app/services/loader.service';
import { InvoicesService } from 'src/app/services/invoices.service';

@Component({
  selector: 'app-email-document',
  templateUrl: './email-document.component.html',
  styleUrls: ['./email-document.component.css']
})
export class EmailDocumentComponent implements OnInit {
  submitted = false;
  sendEmail: FormGroup;
  @Input() public bolDocId;
  @Input() public commInvDocId;
  @Input() public invoiceNumber;
  @Input() public action;
  @Input () public documentUrl;
  @Input () public emailType;
  @Input () public orderNo;


  @Input() public defaultEmail;
  @Input() public saveBolFormData: FormData;
  @Input() public saveCommInvFormData: FormData;
  @Input() public saveCarrierContractFormData: FormData;
  sharedData: any;
  showpopup: boolean=false;
  constructor(public activeModal: NgbActiveModal,public fb:FormBuilder,private  _loaderService: LoadingService,public DocumentService:DocumentService, private toastr: ToastrService, private router: Router, public commonService: CommonService, public invoiceService : InvoicesService) { 
    this.sendEmail = this.sendEmailForm();
    this.commonService.currentData.subscribe((data: any) => {
      this.sharedData = data;
    })
  }

  ngOnInit(): void {
    this.sendEmail.get('email').patchValue(this.defaultEmail);
  }

  sendEmailForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null,
        [Validators.required,
         Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]
        ],
        bccEmail:[null]
      }
    );
  }

  get f() { return this.sendEmail.controls; }

  closeModal() { this.activeModal.close(); }

  async onSubmit(formData){
    this.submitted = false;
    if (this.sendEmail.invalid) {
      return;
    }

    if(formData.email == formData.bccEmail){
      this.toastr.error('BCC email should not be same as email.', '', {
        timeOut: 10000,
      });
      return;
    }

    let inputData ={
      "documentId": '',
      "emailId": formData.email,
      "bccEmailId": formData.bccEmail
    }
    this._loaderService.stringSubject.next(true);
    this.closeModal()

    if(this.action === 'bol'){
      this.sharedData.appLoader = true;
      inputData.documentId = this.bolDocId;
      this.DocumentService.savePdf(this.saveBolFormData).subscribe(result => {
        // this.displayLoader = false;
        this.toastr.success('PDF Saved Successfully.', '', {
          timeOut: 10000,
        });

        this.sendEmailFn(inputData);

        }, error => {
          this.sharedData.appLoader = false;

          if (error.status == 401) {
            this.router.navigate(['/signin']);
          }
          // this.displayLoader = false;
          this.toastr.error('Something Went wrong while saving PDF.', 'Please try again.', {
            timeOut: 10000,
          });
          // this.displayLoader = false;
        }
      );
    }
    else if (this.action === 'invoice'){
      this.sharedData.appLoader = true;
      inputData.documentId = this.invoiceService.documentID$.value;
      this.sendEmailFn(inputData, this.invoiceNumber);

    }
    else if (this.action === 'others'){
      this.sharedData.appLoader = true;

      let inputData2 = {
        "documentUrl": this.documentUrl,
        "emailId": formData.email,
        "bccEmailId": formData.bccEmail,
        ...(this.emailType ? { "emailType": this.emailType } : {}),
        ...(this.orderNo ? { "orderNo": this.orderNo } : {})

      };
      
        this.sendEmailFn(inputData2); 

    }

    else if (this.action === 'commercial'){
      this.sharedData.appLoader = true;
      inputData.documentId = this.commInvDocId;
      if(this.commInvDocId){
        this.sendEmailFn(inputData);
      }else{
        this.DocumentService.saveCommercialPdf(this.saveCommInvFormData).subscribe((result:any) => {
          this.toastr.success('Commercial invoice PDF Saved Successfully.', '', {
            timeOut: 5000,
          });
  
          inputData.documentId = result.documentId;
          this.sendEmailFn(inputData);
  
        }, error => {
          this.sharedData.appLoader = false;
          if (error.status == 401) {
            localStorage.clear();
            this.router.navigate(['/signin']);
          }
          this.toastr.error('Something Went wrong while saving Commercial Invoice.', 'Please try again.', {
            timeOut: 5000,
          });
        });
      }
    }
    else if (this.action === 'insurance'){
      
      this.sharedData.appLoader = true;
      inputData.documentId = "dummy",
      inputData["documentUrl"] = localStorage.getItem('insurancePDFUrl');
      this.sendEmailFn(inputData);
    }
    else{
      return;
    }
  
  }

  sendEmailFn(inputData, invoiceNumber?){
    let obser :any;
    if(this.action == 'bol'){
      obser = this.DocumentService.sendEmail(inputData); 
    }
    else if(this.action == 'others'){
      obser = this.DocumentService.sendOtherDocEmail(inputData); 
    }
    else if(this.action == 'invoice'){
      obser = this.DocumentService.sendEmailInvoice(inputData, invoiceNumber);
    }else if(this.action == 'commercial'){
      obser = this.DocumentService.sendCommercialEmail(inputData);
    }
    else if(this.action == 'insurance'){
      obser = this.DocumentService.sendInsuranceEmail(inputData);
    }
    else{
      return;
    }
    obser.subscribe((result:any) =>{
      this.sharedData.appLoader = false;
      this._loaderService.stringSubject.next(false);

      this.toastr.success('Email sent successfully.', 'Success', {
        timeOut: 10000,
      });

      this.activeModal.close({"success": true});          
    },error=>{
      this._loaderService.stringSubject.next(false);

      this.sharedData.appLoader = false;
      this.activeModal.close({"success": false});
    })
  }
}
