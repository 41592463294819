import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as pdfjsLib from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import * as html2pdf from 'html2pdf.js';
import { LoadingService } from 'src/app/services/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PrintPdfComponent } from '../print-pdf/print-pdf.component';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/services/document.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-pdf-merge-viewer',
  templateUrl: './pdf-merge-viewer.component.html',
  styleUrls: ['./pdf-merge-viewer.component.scss'],
})
export class PdfMergeViewerComponent implements OnInit {
  @Input() requestUrl;
  @Input() docType;

  pdfUrls: string[] = [
    // "https://dev-shipcarte-docs.s3.amazonaws.com/1737257693381-UQZ01310-bol_label.pdf",
    // "https://dev-shipcarte-docs.s3.amazonaws.com/1737257693381-UQZ01310-bol_label.pdf",
    // "https://dev-shipcarte-docs.s3.amazonaws.com/1737037405653-UQZ01310-bol_label.pdf",
    // "https://images.unsplash.com/photo-1736297150541-89378f055b96?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwyfHx8ZW58MHx8fHx8",
    // "https://dev-shipcarte-docs.s3.amazonaws.com/1737172969141-API_Documentation_2024.pdf",
    // "https://dev-shipcarte-docs.s3.amazonaws.com/1737130593623-quote-5833475007626.pdf",
    // "https://dev-shipcarte-docs.s3.amazonaws.com/1737172969141-API_Documentation_2024.pdf",
    // "https://dev-shipcarte-docs.s3.amazonaws.com/1737404057090-Screenshot-11-23-2022-12-51-20-PM-1.png",
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private _loaderService: LoadingService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private docService: DocumentService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    GlobalWorkerOptions.workerSrc = environment.PDF_LIB_CDN_URL;
    this.getData();
    // this.loadAndRenderPDFs();
  }

  getData() {
    this.docService.getHttpData(this.requestUrl).subscribe(
      (data: any) => {
        if (Array.isArray(data) && data.length > 0) {
          const urls = data
            .filter((item) => item.link || item.downloadURL) //* Ensure 'link' exists
            .map((item) =>
              Array.isArray(item.link || item.downloadURL)
                ? item.link || item.downloadURL
                : [item.link || item.downloadURL]
            ) //* Wrap non-array links into an array
            .reduce((acc, curr) => acc.concat(curr), []); //* Flatten the array of arrays

          if (urls.length > 0) {
            this.pdfUrls = urls;
            setTimeout(() => this.loadAndRenderPDFs(), 50);
            return;
          }
        }

        this.closeLoader();
        this.getDocError();
        this.closeModal();
      },
      (err) => {
        // console.error("Error fetching data:", err);
        this.closeLoader();
        this.getDocError();
        this.closeModal();
      }
    );
  }

  getDocError() {
    this.toastr.error('No document available for selected orders.', 'Oops', {
      timeOut: 10000,
    });
  }

  closeModal(error = null) {
    this.activeModal.close(error);
  }

  closeLoader() {
    this._loaderService.stringSubject.next(false);
  }

  OpenPrintPdf(url: string = '') {
    if (!url) return;
    const modalRef = this.modalService.open(PrintPdfComponent, {
      size: 'lg',
      centered: true,
      backdrop: true,
      windowClass: 'my-custom-modal0 w-700',
    });
    modalRef.componentInstance.documentURL =
      this.sanitizer.bypassSecurityTrustResourceUrl(url);
    modalRef.result.then((result) => {
      // this.close();
    });
  }

  printPDFs() {
    this._loaderService.stringSubject.next(true);
    setTimeout(() => {
      this.generatePdfForUpload();
    }, 50);
  }

  async generatePdfForUpload() {
    let currentcomp = this;
    let element = document.querySelector('#pdfContainer');
    const name = `${this.docType}_${new Date().getTime()}.pdf`;
    let opt = {
      margin: 0.1,
      filename: name,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { dpi: 72, scale: 2, letterRendering: true },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait',
        compressPDF: true,
      },
      pageBreak: { mode: 'css', after: '.break-page' },
    };
    let worker = await html2pdf()
      .set(opt)
      .from(element)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        if (totalPages > 30) {
          this.pdfExceedsLimit();
          return Promise.reject('Page limit exceeded');
        }

        return html2pdf().set(opt).from(element).toPdf().output('blob');
      })
      .then((data: Blob) => {
        return data;
      })
      .catch((err) => {
        this.pdfExceedsLimit();
        return null; // Return null or handle the rejection
      });

    if (worker) {
      currentcomp.uploadData(worker);
    }
  }

  uploadData(pdfContent) {
    const formdata: FormData = new FormData();
    const dummyFile = new File(
      [pdfContent],
      `${this.docType}_${new Date().getTime()}.pdf`
    );
    formdata.append('file', dummyFile);

    const url = environment.apiPath + '/api/bol/multiPdf/upload';

    let promise = this.commonService.postFileHttpService(url, formdata);

    promise.then(
      (res: any) => {
        this.OpenPrintPdf(res.downloadUrl);
        this._loaderService.stringSubject.next(false);
        this.cdr.detectChanges();
      },
      (err: any) => {
        this._loaderService.stringSubject.next(false);
        this.cdr.detectChanges();
      }
    );
  }

  downloadPDF() {
    this._loaderService.stringSubject.next(true);
    setTimeout(() => {
      this.downloadPDFContent();
    }, 500);
  }

  async downloadPDFContent() {
    const element = document.querySelector('#pdfContainer');
    const timeStamp = new Date().getTime();
    const opt = {
      margin: 0.1,
      filename: `${this.docType}_${timeStamp}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { dpi: 72, scale: 2, letterRendering: true },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait',
        compressPDF: true,
      },
      pageBreak: { mode: 'css', after: '.break-page' },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        if (totalPages > 30) {
          this.pdfExceedsLimit();
          return Promise.reject('Page limit exceeded');
        }
      })
      .then(() => {
        return html2pdf().set(opt).from(element).save();
      })
      .then(() => {
        this.closeLoader();
        this.cdr.detectChanges();
      })
      .catch((err) => {
        this.pdfExceedsLimit();
      });
  }

  pdfExceedsLimit() {
    this.closeModal({ error: true });
    this.closeLoader();
    this.cdr.detectChanges();
  }

  async loadAndRenderPDFs() {
    try {
      const pdfContainer = document.getElementById('pdfContainer');
      pdfContainer.innerHTML = ''; //* Clear existing content

      const modalWidth = 600; // TODO make this dynamic -> Define the modal width for scaling.
      let totalPages = 0;

      //* Calculate total pages for PDFs and images
      for (const url of this.pdfUrls) {
        const isImage = await this.isImageUrl(url);
        if (isImage) {
          totalPages += 1; //* Each image counts as one page
        } else {
          const pdfDoc = await pdfjsLib.getDocument(url).promise;
          totalPages += pdfDoc.numPages;
        }
      }

      let currentPage = 0;

      for (const url of this.pdfUrls) {
        const isImage = await this.isImageUrl(url);
        if (isImage) {
          currentPage++;
          await this.renderImage(url, pdfContainer, modalWidth);

          if (currentPage < totalPages) {
            this.addPageBreak(pdfContainer);
          }
        } else {
          const pdfDoc = await pdfjsLib.getDocument(url).promise;
          for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
            currentPage++;
            const page = await pdfDoc.getPage(pageNum);
            await this.renderPdfPage(page, pdfContainer, modalWidth);

            if (currentPage < totalPages) {
              this.addPageBreak(pdfContainer);
            }
          }
        }
      }

      this._loaderService.stringSubject.next(false);
      this.cdr.detectChanges();
    } catch (err) {
      this.toastr.error('Some thing went wrong');
      this.closeLoader();
      this.closeModal();
    }
  }

  //* Helper: Check if URL is an image
  async isImageUrl(url: string): Promise<boolean> {
    const regexMatchPdf = /\.(pdf)$/i.test(url);

    if (regexMatchPdf) return false;

    //* if URL ends with an image extension
    const regexMatch = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);

    if (regexMatch) {
      return true;
    }

    //* If not, perform a HEAD request to check the Content-Type
    try {
      const response = await fetch(url, { method: 'HEAD' });
      const contentType = response.headers.get('Content-Type');
      return contentType && contentType.startsWith('image/');
    } catch (error) {
      console.error('Error validating image URL:', error);
      return false;
    }
  }

  //* Helper: Render a single PDF page
  async renderPdfPage(page: any, container: HTMLElement, modalWidth: number) {
    const viewport = page.getViewport({
      scale: this.requestUrl.endsWith('CRS') ? 1.5 : 1,
    });
    const scale = modalWidth / viewport.width;
    const scaledViewport = page.getViewport({ scale });

    const renderScale = 2; //* Adjust for quality
    const highQualityViewport = page.getViewport({
      scale: scale * renderScale,
    });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = highQualityViewport.width;
    canvas.height = highQualityViewport.height;

    const renderContext = {
      canvasContext: context,
      viewport: highQualityViewport,
    };

    await page.render(renderContext).promise;

    canvas.style.width = `${scaledViewport.width}px`;
    canvas.style.height = `${scaledViewport.height}px`;

    const pageDiv = document.createElement('div');
    pageDiv.classList.add('pdf-page');

    pageDiv.style.border = '1px solid #cdcbcb';
    pageDiv.style.marginBottom = '16px';
    pageDiv.style.borderRadius = '4px';
    pageDiv.style.display = 'flex';
    pageDiv.style.padding = '10px';
    pageDiv.style.justifyContent = 'center';

    pageDiv.appendChild(canvas);
    container.appendChild(pageDiv);
  }

  //* Helper: Render an image
  async renderImage(url: string, container: HTMLElement, modalWidth: number) {
    const isBase64 = (str: string): boolean => {
      try {
        return (
          str.startsWith('data:image/') && atob(str.split(',')[1]) !== undefined
        );
      } catch (e) {
        return false;
      }
    };

    //* Function to convert image URL to Base64
    const convertToBase64 = async (url: string): Promise<string> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; //* Allow cross-origin for Base64 conversion
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL('image/png'));
        };
        img.onerror = () => reject(`Failed to load image: ${url}`);
        img.src = url;
      });
    };

    //* Convert URL to Base64 if it's not already Base64
    if (!isBase64(url)) {
      url = await convertToBase64(url);
    }

    //* Render the image
    const img = new Image();
    img.src = url;

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const scale = modalWidth / img.width;
    const scaledWidth = img.width * scale;
    const scaledHeight = img.height * scale;

    canvas.width = scaledWidth;
    canvas.height = scaledHeight;

    context.drawImage(img, 0, 0, scaledWidth, scaledHeight);

    const pageDiv = document.createElement('div');
    pageDiv.classList.add('pdf-page');

    pageDiv.style.border = '1px solid #cdcbcb';
    pageDiv.style.marginBottom = '16px';
    pageDiv.style.borderRadius = '4px';
    pageDiv.style.display = 'flex';
    pageDiv.style.padding = '10px';
    pageDiv.style.justifyContent = 'center';

    pageDiv.appendChild(canvas);
    container.appendChild(pageDiv);
  }

  //* Helper: Add page break
  addPageBreak(container: HTMLElement) {
    const breakDiv = document.createElement('span');
    breakDiv.classList.add('break-page');
    breakDiv.style.pageBreakAfter = 'always';
    breakDiv.style.height = '0';
    container.appendChild(breakDiv);
  }
}

// async downloadPDFContent() {
//   const element = document.querySelector("#pdfContainer");
//   const options = {
//     margin: 0.1,
//     filename: "shipmentLabel.pdf",
//     image: { type: "jpeg", quality: 1 },
//     html2canvas: { dpi: 72, scale: 2, letterRendering: true },
//     jsPDF: {
//       unit: "in",
//       format: "a4",
//       orientation: "portrait",
//       compressPDF: true,
//     },
//     pageBreak: { mode: "css", after: ".break-page" },
//   };

//   try {
//     // Generate the PDF without downloading it to get the total pages
//     const pdfDoc = await html2pdf().set(options).from(element).toPdf().get('pdf');

//     // Get the total number of pages in the generated PDF
//     const totalPages = pdfDoc.internal.pages.length;

//     console.log("Total pages: ", totalPages);  // Log the total number of pages

//     // Trigger the actual download
//     await html2pdf().set(options).from(element).save();

//     // Optionally, you can show the number of pages to the user
//     alert(`The PDF has ${totalPages} pages.`);

//     // Hide the loader after downloading
//     this._loaderService.stringSubject.next(false);
//     this.cdr.detectChanges();
//   } catch (error) {
//     console.error("Error generating or downloading PDF:", error);

//     // Handle any errors
//     this._loaderService.stringSubject.next(false);
//     this.cdr.detectChanges();
//   }
// }

// async loadAndRenderPDFs() {
//   const pdfContainer = document.getElementById("pdfContainer");
//   pdfContainer.innerHTML = ""; //* Clear any existing content before rendering new PDFs

//   const modalWidth = 700; // TODO make this dynamic -> Define the modal width for scaling.
//   let totalPages = 0;

//   //* Calculate the total number of pages for all PDFs
//   for (const pdfUrl of this.pdfUrls) {
//     const pdfDoc = await pdfjsLib.getDocument(pdfUrl).promise;
//     totalPages += pdfDoc.numPages;
//   }

//   let currentPage = 0;

//   for (const pdfUrl of this.pdfUrls) {
//     const pdfDoc = await pdfjsLib.getDocument(pdfUrl).promise;

//     for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
//       currentPage++;

//       const page = await pdfDoc.getPage(pageNum);
//       const viewport = page.getViewport({ scale: 1 });
//       const scale = modalWidth / viewport.width; //* Calculate scale to fit modal width
//       const scaledViewport = page.getViewport({ scale });

//       //* Set a higher internal rendering scale for better quality
//       const renderScale = 2; //* Adjust this value for higher quality (2 or 3)
//       const highQualityViewport = page.getViewport({
//         scale: scale * renderScale,
//       });

//       const canvas = document.createElement("canvas");
//       const context = canvas.getContext("2d");

//       //* Render at higher resolution
//       canvas.width = highQualityViewport.width;
//       canvas.height = highQualityViewport.height;

//       const renderContext = {
//         canvasContext: context,
//         viewport: highQualityViewport,
//       };

//       await page.render(renderContext).promise;

//       //* Use CSS to scale down the canvas for display
//       canvas.style.width = `${scaledViewport.width}px`;
//       canvas.style.height = `${scaledViewport.height}px`;

//       //* Create a div to wrap each canvas
//       const pageDiv = document.createElement("div");
//       pageDiv.classList.add("pdf-page");

//       pageDiv.style.border = "1px solid #cdcbcb";
//       pageDiv.style.marginBottom = "16px";
//       pageDiv.style.borderRadius = "4px";
//       pageDiv.style.display = "flex";
//       pageDiv.style.padding = "10px";
//       pageDiv.style.justifyContent = "center";

//       //* Append canvas to the pageDiv
//       pageDiv.appendChild(canvas);

//       //* Append the pageDiv to the container
//       pdfContainer.appendChild(pageDiv);

//       if (currentPage < totalPages) {
//         const breakDiv = document.createElement("span");
//         breakDiv.classList.add("break-page");
//         breakDiv.style.pageBreakAfter = "always";
//         breakDiv.style.height = "0";
//         pdfContainer.appendChild(breakDiv);
//       } else {
//         this._loaderService.stringSubject.next(false);
//         this.cdr.detectChanges();
//       }
//     }
//   }
// }
