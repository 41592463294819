import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';
import { AddFavoriteComponent } from '../_helpers/popup-dialog/add-favorite/add-favorite.component';
import { EmailDocumentComponent } from '../_helpers/popup-dialog/email-document/email-document.component';
import { ToastrService } from 'ngx-toastr';
import { ViewPodComponent } from '../_helpers/popup-dialog/view-pod/view-pod.component';
import { ShipmentListingService } from './shipment-listing.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
 
  
  quoteId:any;
  orderID:any;
  bolId:any
  priceSummaryData:any;
  Charges=[];
  bolInvoiceDetails:any;
  bolResultData:any;
  commResultData:any
  dimensionBOL:any;
  totalItemBol:number;
  declaredBol:number;
  viewPOD:boolean=false;
  viewDetails:boolean=false;
  orderWeight:any;
  weightUnit:any;
  customQuoteId='';
  shipmentModeStyleCode:any;
  shipmentMode='';
  sidebarToggle:boolean=false;
  editableCustomOrderId='';
  printInvoiceFromInvoices: boolean=false;
  invoiceList:any;
  public currentData: any;
  
  sharedData:any;

  public data = new BehaviorSubject<any>({
    isUserActive: true,
    creditLimit: '',
    appLoader: false
  })
  saveBolFormData: FormData;
  saveCommInvFormData: FormData;
  constructor(public http:HttpClient, public router: Router, private modalService: NgbModal, private toastr: ToastrService,private shipmentService: ShipmentListingService) {
    this.currentData = this.data.asObservable();
    this.currentData.subscribe((data:any)=>{
      this.sharedData=data
    })
  }
  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
    , withCredentials: true
  };
  getCreditData(){
    return this.http.get(environment.apiPath + "/api/creditLimit/get", { withCredentials: true });
  }
  getNotificationData(){
    return this.http.get(environment.apiPath + "/api/notifications/getAll/0/5", { withCredentials: true });
  }
  markedRead(data){
    return this.http.put(environment.apiPath+ "/api/notifications/markAsRead", data ,this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }
  getAddressByZip(zipcode):any{
    return this.http.get(environment.apiPath + "/sc/google/pincode/ws/"+zipcode, { withCredentials: true });
  }
  getShipmentLabelUrls(orderId):any{
    return this.http.get(environment.apiPath + "/api/order/getLabel?orderId="+orderId, { withCredentials: true });
  }

  getDistance(){
    return this.http.get('https://maps.googleapis.com/maps/api/distancematrix/json?origins=Washington%2C%20DC&destinations=New%20York%20City%2C%20NY&units=imperial&key=AIzaSyCX954-_oa1DJ-W9W0tI9kmcZKwFraRdn8');
    // AIzaSyCX954-_oa1DJ-W9W0tI9kmcZKwFraRdn8  currently use api 
    // AIzaSyB6UrbHNiACEp5nHr9sRGlb8CBCN152t-0  existing api
  }

  isUserActiveFn() {
  return  this.http.get(environment.apiPath + "/sc/user/ws/getStatus?id="+JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).userId, {withCredentials: true})    //   this.data.value.isUserActive = res.data;
    //   console.log(this.data.value.isUserActive)
    //   if(res.data){
    //     return true;
    //   }
    // })
  }

  isLoggedSession(){
    // console.log(localStorage.getItem('loggedInUserDetailsFullData'));
    // let data = localStorage.getItem('loggedInUserDetailsFullData');
    // let returnVal: boolean;
    // if(data && data != null){
    //   returnVal = true;
    // }else{
    //   this.router.navigate(['/signin']);
    //   returnVal = false;
    // }
    let returnVal = localStorage.getItem('loggedInUserDetailsFullData') && localStorage.getItem('loggedInUserDetailsFullData') != null ? true : false;
    // !returnVal ? this.router.navigate(['/signin']): '';
    
    return returnVal;
  }

  checkLog(){
    let returnVal = localStorage.getItem('loggedInUserDetailsFullData') && localStorage.getItem('loggedInUserDetailsFullData') != null ? true : false;
    !returnVal ? this.router.navigate(['/signin']): '';
  }
  async encryptData(data: string): Promise<string> {
    try {
      const encoder = new TextEncoder();
      const encodedData = encoder.encode(data);

      // Generate a random initialization vector (IV)
      const iv = crypto.getRandomValues(new Uint8Array(12));

      // Replace 'your-secret-key' with an actual secret key
      const secretKey = await crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode('your-secret-key'),
        { name: 'AES-GCM' },
        false,
        ['encrypt']
      );

      // Encrypt the data using AES-GCM
      const encryptedData = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv: iv },
        secretKey,
        encodedData
      );

      // Combine the IV and encrypted data and encode as a base64 string
      const result = btoa(String.fromCharCode.apply(null, iv) + String.fromCharCode.apply(null, new Uint8Array(encryptedData)));

      return result;
    } catch (error) {
      console.error('Encryption error:', error);
      throw error;
    }
  }

  async dialogOpenForEmail(requiredDataObj) {

    const {modelToOpn, action, defaultEmail, quoteId, orderId, bolDocId, customOrderId, commInvDocId} = requiredDataObj;

    if (modelToOpn === "addFav") {
      const modalRef = this.modalService.open(AddFavoriteComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.quoteId = quoteId;
      modalRef.componentInstance.orderId = orderId;
      modalRef.result.then((result) => {
        if (result['success'] == true) {
          this.toastr.success('Order saved as a favorite.', 'Success', {
            timeOut: 30000,
          });
          localStorage.setItem("fav_" + quoteId, "true");
        }
      });
    }
    if (modelToOpn === "emailDoc") {
      // if (localStorage.getItem("com_documentId_" + this.quoteId) == null && action != "bol") {
      //   this.toastr.error('First create commercial invoice.', 'Please', {
      //     timeOut: 30000,
      //   });
      //   return;
      // }
      
      const requiredDataObjToSavePdf = {
        quoteId: quoteId,
        orderId: orderId,
        bolDocId: bolDocId,
        customOrderId: customOrderId
      }
      if(action == 'bol'){
        this.saveBolFormData = await this.saveBolPdf(requiredDataObjToSavePdf);
      }else if(action == 'commercial'){
        this.saveCommInvFormData = await this.saveCommercialPdf(requiredDataObjToSavePdf);
      }
      const modalRef = this.modalService.open(EmailDocumentComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.bolDocId = bolDocId;
      modalRef.componentInstance.commInvDocId = commInvDocId;
      modalRef.componentInstance.action = action;
      modalRef.componentInstance.documentUrl = requiredDataObj.url;
      modalRef.componentInstance.emailType = requiredDataObj.emailType;
      modalRef.componentInstance.orderNo = requiredDataObj.orderNo;


      modalRef.componentInstance.defaultEmail = defaultEmail;
      modalRef.componentInstance.saveBolFormData = this.saveBolFormData;
      modalRef.componentInstance.saveCommInvFormData = this.saveCommInvFormData;
      modalRef.result.then((result) => {
        if (result.success) {
          this.toastr.success('Email sent successfully.', 'Success', {
            timeOut: 10000,
          });
        } else {
          this.toastr.error('Error, While sending email.', 'Oops', {
            timeOut: 10000,
          });
        }
      });
    }
  }

  async saveCommercialPdf(requiredDataObj):Promise<FormData> {
    // let currentcomp = this;
    this.sharedData.appLoader = true;
    // await html2canvas(document.querySelector('#DownloadCommercialPdfData'), { useCORS: false }).then(function (canvas) {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   let img = new Image();
    //   img.src = canvas.toDataURL('image/png');
    //   img.onload = () => {
    //     var position = 0;
    //     let pdf = new jsPDF('p', 'mm');
    //     pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //       heightLeft -= pageHeight;
    //     }
    //     var blobData = pdf.output("blob");
    //     currentcomp.sendCommercialPDFToServer(blobData);
    //   };
    // });

    var element = document.querySelector('#DownloadCommercialPdfData');
    var opt = {
      margin: 0.1,
      filename: "Commercial_Invoice_" + requiredDataObj.quoteId + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })

    return this.sendCommercialPDFToServer(worker, requiredDataObj);
  }
  sendCommercialPDFToServer(pdfContent, requiredDataObj) {
    // console.log( this.displayLoader)
    const formdata: FormData = new FormData();
    formdata.append("orderId", requiredDataObj.orderId);
    formdata.append("quoteId", requiredDataObj.quoteId);
    // formdata.append("commInvId", this.commercialInvoiceId)

    var date = new Date().valueOf();

    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });



    var dummyFile = new File([pdfContent], "COM_" + requiredDataObj.customOrderId + ".pdf");
    formdata.append("file", dummyFile);
    this.sharedData.appLoader=false;
    return formdata;

    // this.docService.saveCommercialPdf(formdata).subscribe(result => {
    //   this.displayLoader = false;
    //   this.toastr.success('Commercial invoice PDF Saved Successfully.', '', {
    //     timeOut: 5000,
    //   });
    // }, error => {
    //   if (error.status == 401) {
    //     localStorage.clear();
    //     this.router.navigate(['/signin']);
    //   }
    //   this.displayLoader = false;
    //   this.toastr.error('Something Went wrong while saving Commercial Invoice.', 'Please try again.', {
    //     timeOut: 5000,
    //   });
    //   this.displayLoader = false;
    // });
  }

  async saveBolPdf(requiredDataObj):Promise<FormData> {
    this.sharedData.appLoader = true;
    // let currentcomp = this;
    // html2canvas(document.querySelector('#content'),{useCORS:false}).then(function(canvas) {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   let img = new Image();
    //   img.src = canvas.toDataURL('image/png');
    //   img.onload = function () {
    //     var position = 0;
    //     let pdf = new jsPDF('p', 'mm');
    //     pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //       heightLeft -= pageHeight;
    //     }
    //     var blobData = pdf.output("blob");
    //     currentcomp.sendPDFToServer(blobData);
    //   };
    // });
    var element = document.querySelector('#contentbol');
    var opt = {
      margin: 0.1,
      filename: "BOL_" + requiredDataObj.quoteId + ".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })
    // console.log(worker);
    return this.sendBolPDFToServer(worker, requiredDataObj);
  }

  sendBolPDFToServer(pdfContent, requiredDataObj) {

    const formdata: FormData = new FormData();
    formdata.append("documentId", requiredDataObj.bolDocId);
    formdata.append("orderId", requiredDataObj.orderId);

    var date = new Date().valueOf();
    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });

    var dummyFile = new File([pdfContent], "BOL_" + requiredDataObj.bolDocId + ".pdf");
    formdata.append("file", dummyFile);

    this.sharedData.appLoader=false;
    return formdata;
    // this.docService.savePdf(formdata).subscribe(result => {
    //   this.displayLoader = false;
    //   this.toastr.success('PDF Saved Successfully.', '', {
    //     timeOut: 10000,
    //   });
    // }, error => {
    //   if (error.status == 401) {
    //     this.router.navigate(['/signin']);
    //   }
    //   this.displayLoader = false;
    //   this.toastr.error('Something Went wrong while saving PDF.', 'Please try again.', {
    //     timeOut: 10000,
    //   });
    //   this.displayLoader = false;
    // });

  }
  postFileHttpService(url, formdata) {
    //const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    let promise = new Promise((resolve, reject) => {
      this.http.post(url, formdata, { withCredentials: true
        }).subscribe(
        res => resolve(res),
        error => {
          if (error.status === 400) {
            reject(error["error"])
          } else if (error.status === 401) {

            reject(error["error"]["data"]);
          } else if (error.status === 503) {
            error["error"] = { message: "Service Unavailable." };
            reject(error["error"]);
          } else if (error.status === 0) {

            reject(error);
          } else {

            reject(error["error"]);
          }
        },

      );
    });
    return promise;
  }
  viewPod(data, pdfurl) {
    if(pdfurl){
      const requiredData = {
        pdfUrl: pdfurl
      }
      this.openModal('view-pod', requiredData);
    }else{
      this.shipmentService.getPodDoc(data.orderId, data.ladingNo).subscribe((response: any) => {
        if (response && response.downloadURL) {
          const requiredData = {
            pdfUrl: response.downloadURL
          }
          this.openModal('view-pod', requiredData);
        } else {
          this.toastr.error(response.message)
        }
      }, error => {
        this.toastr.error("Something Went Wrong!")
      })
    }
  }
  openModal(type, requiredData){
    if(type=='view-pod'){
      const modalRef = this.modalService.open(ViewPodComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'viewPodModal', keyboard: true });
        // Pass the PDF content to the modal component
      modalRef.componentInstance.pdfurl = requiredData.pdfUrl;
    }else{
      return;
    }
  }


}// Generate a random 256-bit (32-byte) key

@Injectable()
export class UnitedStatesDateParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct | null {
    if (value != null) {
      const parts = value.split('/');
      if (parts.length === 3 && this.isNumber(parts[0]) && this.isNumber(parts[1]) && this.isNumber(parts[2])) {
        return { month: parseInt(parts[0]), day: parseInt(parts[1]), year: parseInt(parts[2]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date && this.isNumber(date.day) && this.isNumber(date.month) && this.isNumber(date.year)
      ? `${this.padNumber(date.month)}-${this.padNumber(date.day)}-${date.year}`
      : '';
  }

  private isNumber(value: any): value is number {
    return !isNaN(parseInt(value));
  }

  private padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

}