<div class="m-0" id="printable">
    <div id="DownloadPdfData" class="col-12 multiple-bol">
      <app-spinner></app-spinner>
      <div class="d-flex justify-content-between pt-3">
        <div>
          <div class="d-flex" *ngIf="bolDataList.length > 0">
          <span class="icons mr-2 cursor_pointer"><img src="assets/img/shipment/fi-rr-print.svg" alt="" (click)="printPdf()" /></span>
          <div class="mr-2 text-start h_35">
            <a style="
                padding: 5px;
                border: 2px solid #d4d4d4;
                border-radius: 5px;
                opacity: 0.7;
                cursor: pointer;
              "><img src="/assets/img/shipment/fi-rr-download.svg" alt="" (click)="download()" /></a>
          </div>
        </div>
        </div>
        <div class="d-flex justify-content-end h_15">
          <img class="cursor_pointer" src="assets/img/Currier-Selection/close-x.svg" alt="" (click)="closeModal()" />
        </div>
      </div>
  
      <div>
        <!DOCTYPE html>
        <html>
  
        <head>
          <style type="text/css">
            .blo_connaissement_right1 {
              font-family: "Poppins", sans-serif;
              margin-top: 20px;
              text-align: center;
            }
  
            .blo_connaissement_right1 .blo_left1 {
              font-size: 11px;
            }
  
            .blo_connaissement_right1 .blo_left1 div {
              display: flex;
              justify-content: end;
            }
  
            .blo_connaissement_right1 .blo_right1 {
              font-size: 11px;
            }
  
            .blo_connaissement_right1 .blo_right1 div {
              display: flex;
              justify-content: end;
            }
  
            .order-details_section1 {
              /* width: 100%; */
              /* float: left; */
              margin: 0 0 6px 0 !important;
              font-family: "Poppins", sans-serif;
  
            }
  
  
            .order-details1 {
              /* width: 23%; */
              /* float: left; */
              font-size: 12px !important;
              font-weight: 600;
              /* padding: 4px; */
              font-family: "Poppins", sans-serif;
            }
  
            .table_start1 {
              width: 100%;
              float: left;
              font-family: "Poppins", sans-serif;
            }
  
            .table-heading1 {
              width: 100%;
              float: left;
              padding: 0;
              font-size: 10px;
              background-color: #E2E7EE77;
              border-top: 1px solid #D4D4D4;
              border-left: 1px solid #D4D4D4;
              border-right: 1px solid #D4D4D4;
            }
  
            .shipper-heading1 {
              width: 50%;
              float: left;
              font-weight: 600;
              padding: 4px;
            }
  
            .consignee-heading1 {
              width: 50%;
              float: left;
              font-weight: 600;
              padding: 4px 0px;
            }
  
            .table-data1 {
              width: 100%;
              float: left;
              border: 1px solid #D4D4D4;
              padding: 0;
              font-size: 10px;
              line-height: 1.2;
              font-weight: 400;
            }
  
            .shipper_box1 {
              width: 50%;
              float: left;
              padding: 4px 6px;
            }
  
            .shipper_box1 div {
              padding: 1px 0 1px 0;
            }
  
            .consignee_box1 {
              width: 50%;
              float: left;
              padding: 4px;
            }
  
            .consignee_box1 div {
              padding: 1px 0 1px 0;
            }
  
            .bill_freight_strip1 {
              width: 100%;
              float: left;
              background-color: #E2E7EE77;
              border: 1px solid #D4D4D4;
              padding: 5px;
              font-size: 10px;
              text-align: center;
            }
  
            .shipping_charges_strip1 {
              width: 100%;
              float: left;
              border: 1px solid #D4D4D4;
              padding: 0;
              font-size: 12px;
              text-align: center;
              text-transform: uppercase;
            }
  
            .received_text_section1 {
              width: 100%;
              float: left;
              border: 1px solid #D4D4D4;
              padding-left: 3px;
              font-size: 8px;
              font-weight: 500;
              font-family: "poppins", sans-serif;
  
            }
  
            .pro_details_table1 {
              width: 100%;
              float: left;
              font-family: "poppins", sans-serif;
            }
  
            .pro_details_table1 table th {
              font-weight: bold;
              font-size: 14px;
              font-family: "poppins", sans-serif;
              text-align: left;
              float: left;
              padding: 2px !important;
              margin: 0 2px 0 0 !important;
            }
  
            .pro_details_table1 table td {
              font-size: 10px;
              font-weight: 400;
              width: 100%;
              font-family: "poppins", sans-serif;
              float: left;
              padding: 2px !important;
              /* background-color: #E2E7EE77; */
  
            }
  
            .pro_details_table1 tbody {
              width: 100%;
              float: left;
            }
  
            .yellow-header1 {
              background: #f7c765;
              width: 100%;
              float: left;
              font-size: 14px;
              font-weight: 600;
              padding: 0 !important;
            }
  
  
            .shipment_size_box1 {
              width: 100%;
              float: left;
              font-family: "poppins", sans-serif;
              border: 1px solid #D4D4D4;
  
            }
  
            .shipment_box_11 {
              width: 33%;
              float: left;
            }
  
            .orange_heading {
              color: #FF8D00;
              font-size: 13px;
              font-weight: 600;
            }
  
            .Declared_box1 {
              width: 100%;
              float: left;
              padding: 5px;
              border: 1px solid #D4D4D4;
            }
  
            .consignor_signature_box1 {
              width: 100%;
              float: left;
              padding: 3px;
              font-size: 9px;
              font-family: "poppins", sans-serif;
              font-weight: 400;
              border: 1px solid #D4D4D4;
              padding: 5px;
            }
  
            .freight_collect_shipments_box1 {
              width: 100%;
              float: left;
              padding: 3px 2px;
              /* border: 1px solid #D4D4D4; */
              background-color: #E2E7EE77;
            }
  
            .signature_line1 {
              width: 29%;
              height: 1px;
              float: left;
              background: #868585;
              margin: 10px 0 0 0;
            }
  
            .page_heading {
              font-size: 16px;
              font-family: "poppins", sans-serif;
            }
  
            .second-sec h5 {
              font-size: 10px !important;
            }
  
            .second-sec p {
              font-size: 10px !important;
              line-height: 1.6 !important;
            }
  
            .page-break {
              page-break-after: always;
              page-break-inside: avoid;
              clear: both;
            }
  
            .page-break-before {
              page-break-before: always;
              page-break-inside: avoid;
              clear: both;
            }
          </style>
  
        </head>
  
        <body>
          <div style="background: #fff;margin: auto;width: 100%; 
  overflow: hidden;">
      <div id="contentbol" #contentbol>
            <div  class="bol-page_section mt-3 mb-3"
              *ngFor="let bolData of bolDataList;let i = index"
              style="background: #fff;padding: 3px;height: auto;border: 1px solid #D4D4D4; border-radius: 6px;float: left;">
              <div>
                <div class=" row blo_connaissement_section  mb-0">
                  <div class=" col-4 blo_connaissement_left1 text-start" style="display: flex;">
                    <img style="width: 220px;" src="assets/img/shipcarte-logo-350x130.png" alt="" />
  
                  </div>
                  <div class=" col-8 blo_connaissement_right1 text-end"
                    style="margin-top: 0px !important; padding-top: 16px; display: flex; justify-content: end;">
                    <div class="row" style="justify-content: end; margin-bottom: 0px !important;">
                      <div class=" blo_left1" style="line-height: 1.2; margin-right: 16px; margin-top: 5px;">
                        <div style="color:#FF8D00;font-weight: 600;">ATTENTION CARRIER:
                        </div>
                        <div>SHIPCARTE ORDER NO. MUST</div>
                        <div>APPEAR ON THE FREIGHT BILL</div>
                      </div>
                      <!-- <div class=" col-6 blo_right1">
                                  <div style="color:#FF8D00;font-weight: 600;">ATTENTION PORTEUR:
                                  </div>
                                  <div>SHIPCARTE NUMERO DESTINATAIRE</div>
                                  <div>COMMANDE NO. DOIT APPARAIT</div>
                                  <div>SUR LE PROJET DE LOI DE FRET</div>
                              </div> -->
                    </div>
                    <div>
                      <h3 style="font-size: 20px !important;margin-bottom: 0;">BILL OF LADING
                      </h3>
                      <div>{{bolData?.bolNumber}}</div>
                    </div>
  
                  </div>
                </div>
                <div class="row order-details_section1">
                  <div class="col-2 order-details1 text-start">
                    <div>ORDER NO.</div>
                    <!-- <div>NUMERO DE COMMANDE</div> -->
                    <div style="color:#FF8D00; font-size: 12px !important; font-weight: 400;">
                      {{bolData?.orderNumber}}</div>
                  </div>
                  <div class="col-3 p-0 order-details1 text-start">
                    <div>SHIP DATE</div>
                    <!-- <div>D'EXPEDITION</div> -->
                    <div style="font-size: 12px !important; font-weight:400">
                      {{ bolData?.shipDate ? (bolData?.shipDate | date:'MM-dd-yyyy') : '--' }}
                    </div>
                  </div>
                  <div class="col-5 p-0 order-details1 text-start">
                    <div class="d-flex">
                      <span style="width: 49%;">CUSTOMER REF NO.</span>
                      <span style="width: 2%;">/</span>
                      <span style="width: 49%;">REFERENCE CLIENT NO.</span>
                    </div>
                    <div class="d-flex" style="font-size: 12px !important; font-weight:400">
                      <span style="width: 49%; word-break: break-all;">-</span>
                      <span style="width: 2%;">/</span>
                      <span style="width: 49%; word-break: break-all;">{{bolData?.customerNumber || '--'}}</span>
                    </div>
                  </div>
                  <div class="col-2 order-details1 text-start">
                    <div>ARRIVAL DATE</div>
                    <!-- <div>DATE D'ARRIVEE</div> -->
                    <div style="font-size: 12px !important; font-weight:400">--</div>
                  </div>
                </div>
                <div class="table_start1">
                  <div class="table-heading1">
                    <div class="shipper-heading1">SHIPPER </div>
                    <div class="consignee-heading1">CONSIGNEE </div>
                  </div>
                  <div class="table-data1">
                    <div class="shipper_box1 mr-1" style="border-right: 1px solid #D4D4D4; width: 49%;">
                      <div>{{bolData?.shipperAddress?.company | uppercase}}</div>
                      <!-- <div>{{BOLd?.shipperfullAddr | uppercase}}</div> -->
                      <div>{{bolData?.shipperAddress?.addrLine1 | uppercase}}</div>
                      <!-- <div>{{BOLd?.shipperstate | uppercase}}</div> -->
                      <div>{{bolData?.shipperAddress?.city | uppercase}} {{bolData?.shipperAddress?.state | uppercase}}
                        {{bolData?.shipperAddress?.zipCode |
                        uppercase}},
                        {{bolData?.shipperAddress?.country | uppercase}}</div>
                      <div class="row pb-0" style="margin: unset !important;">
                        <div class="col-6 pb-0">
                          <div class="col-12 d-flex pb-0">
                            <div><b>CONTACT:</b></div>
                            <div class="ml-1" style="word-break: break-all;"> {{bolData?.shipperAddress?.firstName || '' |
                              uppercase}}
                              {{bolData?.shipperAddress?.lastName || '' |
                              uppercase}}</div>
                          </div>
                        </div>
                        <div class="col-6 pb-0">
                          <div class="col-12 d-flex pb-0">
                            <div> <b>TEL:</b></div>
                            <div class="ml-1" style="word-break: break-all;"> {{bolData?.shipperAddress?.phoneNumber ||
                              ''}}</div>
                          </div>
  
  
                        </div>
                      </div>
                      <!-- <div style="float:left;margin:0px 55px 0px 0px;"><b>TEL:</b>
                                      {{resultData ? resultData['shipperAddress']['phoneNumber'] : ''
                                      }}</div> -->
                      <div><b>BUSINESS TYPE:</b> {{bolData?.shipperAddress?.addrTypeId || '--' | uppercase}}</div>
                      <div><b>PICK UP DATE:</b> {{ bolData?.pickupDate ? (bolData?.pickupDate | date:'MM-dd-yyyy') : '--'
                        }}
                      </div>
                      <div><b>HOURS OF PICK UP:</b> {{ bolData?.pickupFromTime ? bolData.pickupFromTime: '--' }} to
                        {{ bolData?.pickupToTime ?
                        bolData.pickupToTime: '--' }}</div>
                      <div><b>P/U INSTRUCTIONS:</b> {{ bolData?.pickupInstruction ? bolData.pickupInstruction : '--'
                        }}</div>
                      <div>
                        <b>SHIPPER:</b>
                        <ng-container
                          *ngIf="bolData?.shipperAddress.extraServices && bolData?.shipperAddress.extraServices.length > 0; else noServices">
                          <ng-container
                            *ngFor="let extraService of bolData?.shipperAddress.extraServices; let last = last">
                            {{ extraService }}{{ !last ? ',' : '' }}
                          </ng-container>
                        </ng-container>
                        <ng-template #noServices>--</ng-template>
                      </div>
                    </div>
                    <div class="consignee_box1">
                      <div>{{bolData?.consigneeAddress?.consigneecompany | uppercase}}</div>
                      <!-- <div>{{BOLd?.consigneefullAddr | uppercase}}</div> -->
                      <div>{{bolData?.consigneeAddress?.addrLine1 | uppercase}}</div>
                      <!-- <div>{{BOLd?.consigneestate | uppercase}}</div> -->
                      <div>{{bolData?.consigneeAddress?.city | uppercase}} {{bolData?.consigneeAddress?.state |
                        uppercase}} {{bolData?.consigneeAddress?.zipCode |
                        uppercase}},
                        {{bolData?.consigneeAddress?.country | uppercase}}</div>
                      <div class="row pb-0" style="margin: unset !important;">
                        <div class="col-6 pb-0">
                          <div class="col-12 d-flex pb-0">
                            <div>
                              <b>CONTACT:</b>
                            </div>
                            <div class="ml-1" style="word-break: break-all;"> {{bolData?.consigneeAddress?.firstName || ''
                              | uppercase}}
                              {{bolData?.consigneeAddress?.lastName || '' |
                              uppercase}}</div>
                          </div>
                        </div>
                        <div class="col-6 pb-0">
                          <div class="col-12 d-flex pb-0">
                            <div> <b>TEL:</b></div>
                            <div class="ml-1" style="word-break: break-all;"> {{bolData?.consigneeAddress?.phoneNumber ||
                              ''}}</div>
                          </div>
  
  
                        </div>
                      </div>
                      <!-- <div><b>CONTACT:</b> {{resultData ?
                                      resultData['consigneeAddress']['firstName'] : '' | uppercase}}
                                      {{resultData ? resultData['consigneeAddress']['lastName'] : '' |
                                      uppercase}}</div>
                                  <div style="float:left;margin:0px 55px 0px 0px;"><b>TEL:</b>
                                      {{resultData ? resultData['consigneeAddress']['phoneNumber'] :
                                      ''}}</div> -->
                      <div><b>BUSINESS TYPE:</b> {{bolData?.consigneeAddress?.addrTypeId || '--' | uppercase}}</div>
                      <div><b>ETA DELIVERY DATE:</b> {{ bolData?.deliveryDate ? (bolData?.deliveryDate |
                        date:'MM-dd-yyyy') :
                        '--' }}</div>
                      <div><b>HOURS OF DELIVERY:</b>{{ bolData?.deliveryFromTime ? bolData.deliveryFromTime: '--' }}
                        to {{ bolData?.deliveryToTime ?
                        bolData.deliveryToTime: '--' }}</div>
                      <div><b>DEL INSTRUCTIONS:</b> {{ bolData?.deliveryInstruction ? bolData.deliveryInstruction :
                        '--' }}</div>
                      <div>
                        <b>CONSIGNEE:</b>
                        <ng-container
                          *ngIf="resultData?.consigneeAddress.extraServices && resultData?.consigneeAddress.extraServices.length > 0; else noServices">
                          <ng-container
                            *ngFor="let extraService of resultData?.consigneeAddress.extraServices; let last = last">
                            {{ extraService }}{{ !last ? ',' : '' }}
                          </ng-container>
                        </ng-container>
                        <ng-template #noServices>--</ng-template>
                      </div>
                    </div>
                  </div>
                  <!-- <div style="width: 100%; font-size: 12px; line-height: 1; padding: 3px;">
  
                              <span style="font-weight: 500; font-size: 9px;"
                                  *ngIf="BOLd?.specialInstruction!=''">Instruction:</span><span
                                  style="font-size: 9px;">{{BOLd?.specialInstruction}}
                              </span>
                              <div style="margin-top: 5px;"><span
                                      style="font-weight: 500; font-size: 10px;">PickUp
                                      Date:</span><span style="font-size: 10px;">{{BOLd?.pickupDate
                                      |date:'MM-dd-yyyy' }}</span>
                              </div>
                          </div> -->
                  <div class="bill_freight_strip1">
                    <b> BILL FREIGHT CHARGES TO THE FOLLOWING PARTY</b>
                  </div>
                  <div class="shipping_charges_strip1" style="text-transform: capitalize; line-height: 1.2">
                    <b>SHIPCARTE </b> <span *ngIf="BOLd && BOLd.carrierAccountNo"> ACCOUNT NO -
                      {{bolData?bolData.carrierAccountNo?bolData.carrierAccountNo:"":""}}</span> <br>
                    <b>{{bolData?.carrierBolDisplayAddress || '1033 JAYSON COURT, MISSISSAUGA, ON L4W 2P4, CANADA'}}
                    </b>
                  </div>
                  <div class="received_text_section1" style="line-height: 1.4;">
                    RECEIVED, subject to the classifications and lawfully filed tariffs in
                    effect on the date of issue
                    of this Bill Of Lading, the property described below and in apparent
                    good order, except as noted
                    (contents and condition of contents of packages unkown), marked,
                    consigned, and destined, as indicated
                    above which said carrier (the word carrier being understood throughout
                    this contract as meaning any person
                    or corporation in possession of the property under the contract) agrees
                    to carry to its usual place of delivery
                    of said destination, if on its route, otherwise to deliver to another
                    carrier on the route to said destination.
                    It is mutually agreed as to each carrier of all or any said property
                    over all or any portion of said route to
                    destination and as to each party at time interested in all or any of
                    said property, that every service to be
                    performed hereunder shall be subject to all the conditions not
                    prohibited by law, whether printed or written,
                    herein contained, including the conditions on the back or attached
                    hereof, which are hereby agreed toby the
                    shipper and accepted for himself and his assigns.
                  </div>
                </div>
                <div class="pro_details_table1">
                  <table style="width: 100%;">
                    <tr class="yellow-header1"
                      style="width: 100%;float: left; display: flex; justify-content: space-between;">
                      <th style="width: 8%;padding-left:3px">QTY</th>
                      <th style="width: 12%;">TYPE</th>
                      <th style="width: 19%;">DIMENSION </th>
                      <th style="width: 15%;">WEIGHT</th>
                      <th style="width: 20%"> COMMODITY DESCRIPTION</th>
                      <th style="width: 12%;">CLASS</th>
                      <th style="width: 7%;">NMFC</th>
                      <th style="width: 9%;">HAZMAT</th>
                    </tr>
                    <ng-container *ngFor="let keyData of bolData ? bolData['shipmentItems'] : []">
                      <tr
                        style="padding: 0px 0px;width: 100%;float: left; background-color: #E2E7EE; display: flex; justify-content: space-between;"
                        [ngStyle]="{'border-bottom': keyData?.hazMat ? '' : '2px solid #D4D4D4'}">
                        <td style="width: 8%;padding-left:3px; ">{{keyData.qty}}</td>
                        <td style="width: 12%; text-transform: capitalize;">
                          {{keyData.packagingCode ? keyData.packagingCode :
                          "pallets"}}</td>
                        <td style="width: 19%;"> {{keyData.length | number:'1.2-2'}} L X
                          {{keyData.breadth | number:'1.2-2'}} W X {{keyData.height |
                          number:'1.2-2'}} H</td>
                        <!-- <td style="width: 35%;">{{keyData.description ? keyData.description
                                          : '--'}}</td> -->
                        <td style="width: 15%;">
                          {{keyData.totalWeight * keyData.qty | number:'1.2-2'}} {{keyData.wghtUnit}}
                        </td>
                        <td style="width: 20%;">{{keyData.description ? keyData.description : '--'}}</td>
                        <td style="width: 12%;">{{keyData.freightClass ?
                          keyData.freightClass : '--'}}
                        </td>
                        <td style="width: 7%;">{{keyData.nmfc ? keyData.nmfc : '--'}}</td>
                        <td style="width: 9%;">{{keyData.hazMat ? 'Yes' : 'No'}}</td>
                      </tr>
                      <!-- <tr style="padding: 2px 0px;width: 100%;float: left;">
                                      <td
                                          style="width:100%;padding-left:3px; background-color: #E2E7EE77; font-size: 12px; font-family: poppins,sans-serif;">
                                          <b>Dimensions :</b> {{dynamicDimension.length | number:'1.2-2'}}
                                          L x {{dynamicDimension.width | number:'1.2-2'}} W x
                                          {{dynamicDimension.height | number:'1.2-2'}} H
                                      </td>
                                  </tr> -->
                      <ng-container *ngIf="keyData?.hazMat">
                        <tr
                          style="padding: 0px 0px;width: 100%;float: left; background-color: #E2E7EE; display: flex; justify-content: flex-end;">
                          <td style="width: 8%;"><b>TYPE</b></td>
                          <td style="width: 12%;"><b>UN NUMBER</b></td>
                          <td style="width: 22%;"><b>PROPER SHIPPING NAME</b></td>
                          <td style="width: 15%;"><b>HAZARD CLASS</b></td>
                          <td style="width: 15%;"><b>PACKAGE GROUP</b></td>
                          <td style="width: 15%;"><b>PACKAGE TYPE</b></td>
                        </tr>
                        <tr
                          style="padding: 0px 0px;width: 100%;float: left; background-color: #E2E7EE; display: flex; justify-content: flex-end;"
                          *ngFor="let key of keyData.hazMatDetails "
                          [ngStyle]="{'border-bottom': keyData?.hazMat ? '2px solid #D4D4D4' : ''}">
                          <td style="width: 8%;">{{key.unNumType}}</td>
                          <td style="width: 12%;">{{key.unNumber}}</td>
                          <td style="width: 22%;">{{key.properShippingName}}</td>
                          <td style="width: 15%;">{{key.hazardClass}}</td>
                          <td style="width: 15%;">{{key.packageGroup | uppercase}}</td>
                          <td style="width: 15%;">{{key.hazardPackagingType | titlecase}}</td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </table>
                </div>
                <div class="shipment_size_box1">
                  <div style="width: 100%;float: left;padding: 0px 1px 5px 3px;">
                    <div class="shipment_box_11">
                      <div style="font-size: 12px; font-weight: 600; font-family: poppins,sans-serif;">
                        TRAILER LOADED:</div>
                      <div style="width: 43%;float: left; display: flex;">
                        <input type="checkbox" id="" name="" value="" style="position: relative;">
                        <label for="" style="font-size:10px !important; font-weight: 400; font-family: poppins,sans-serif;margin-top: 8px;
                                          margin-left: 5px;">
                          By Shipper</label>
                      </div>
                      <div style="width: 43%;float: left; display: flex; align-items: center;">
                        <input type="checkbox" id="" name="" value="" style="position: relative;">
                        <label for="" style="font-size:10px !important;font-weight: 400; font-family: poppins,sans-serif;margin-top: 8px;
                                          margin-left: 5px;">
                          By Driver</label>
                      </div>
                    </div>
                    <div class="shipment_box_11">
                      <div style="font-size: 12px; font-weight: 600; font-family: poppins,sans-serif;">
                        FREIGHT COUNTED:</div>
                      <div style="width: 43%;float: left; display: flex; align-items: center ;">
                        <input type="checkbox" id="" name="" value="" style="position: relative;">
                        <label for="" style="font-size:10px !important;font-weight: 400; font-family: poppins,sans-serif;     margin-top: 8px;
                                          margin-left: 5px;">
                          By Shipper</label>
                      </div>
                      <div style="width: 43%;float: left; display: flex; align-items: center;">
                        <input type="checkbox" id="" name="" value="" style="position: relative;">
                        <label for="" style="font-size:10px !important;font-weight: 400; font-family: poppins,sans-serif;margin-top: 8px;
                                          margin-left: 5px;">
                          By Driver</label>
                      </div>
                    </div>
                    <div class="shipment_box_11"
                      style="text-align: right;font-size: 14px; font-weight: 600; font-family: poppins,sans-serif;">
                      <div class="orange_heading">SHIPMENT SIZE
                      </div>
                      <div tyle="text-align: right;font-size: 10px; font-weight: 600; font-family: poppins,sans-serif;">
  
  
                        <div tyle="text-align: right;font-size: 10px; font-weight: 600; font-family: poppins,sans-serif;">
                          <div>
                              <span>
                                {{bolData?.summary}},
                              </span>
  
                          </div>
                          <div >
                            {{bolData?.totalWeight }}
                          </div>
  
                        </div>
  
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%; display: flex;">
                    <div style="width: 62%;float:left;">
                      <div class="Declared_box1">
                        <div 
                          *ngIf="bolData?.isInsurance"
                          style="font-size: 12px; font-weight: 600; font-family: poppins,sans-serif;">
                          Declared value: ${{bolData?.declaredValue}} &nbsp; &nbsp;&nbsp;
                          &nbsp;"NOT TO BE INSURED BY CARRIER"</div>
                        <div style="font-size: 10px; padding: 2px; line-height: 1.4;">
                          Maximum liability of
                          $2.00/lb or $4.41/kg computed on the total actual weight
                          unless declared valuation states otherwise. please see
                          additional terms and conditions on reverse.</div>
                      </div>
                      <div class="freight_collect_shipments_box1">
                        <div
                          style="font-size: 10px; font-weight: 600; font-family: poppins,sans-serif; padding-left: 4px;">
                          FOR FREIGHT COLLECT SHIPMENTS</div>
                      </div>
                      <div class="consignor_signature_box1">
                        <div style="padding:2px; line-height: 1.4;"> I hereby declare
                          that the contents of
                          this consignment are fully and accurately described above
                          by proper shipping name and are classified, packed, marked
                          and labeled, and are in all respects
                          in proper condition for transport by rail, highway, water
                          according to applicable international and
                          national regulations</div>
                        <div
                          style="width:40%;float: left;padding: 0px; font-size: 10px; font-family: poppins,sans-serif; font-weight: 400;">
                          CONSIGNOR SIGNATURE</div>
                        <div class="signature_line1"></div>
                      </div>
                    </div>
                    <div
                      style="width: 38%;float: left;border-left: 1px solid #ccc;border-top: 1px solid #ccc;padding: 3px 0 0 4px;">
                      <div class="p-0"
                        style="font-size: 10px;font-weight: 600; font-family: poppins,sans-serif; padding: 7px; border-bottom: ipx solid #D4D4D4; line-height: 1.4;">
                        <div style="font-weight: 400; font-size: 8px;">FOR INTERNATIONAL SHIPMENTS PLEASE INDICATE BELOW
                          THE NAME
                          AND PHONE NUMBER OF BROKER </div>
                        <div style="font-size: 10px;font-weight: 600;padding: 4px 0 0 0;">
                          BROKER NAME : {{bolData?.brokerName}}</div>
                        <div style="width: 50%;float: left;font-weight: 600;padding: 4px 0 0 0;">
                          PHONE: {{bolData?.brokerPhone}}</div>
                        <div style="width: 50%;float: left;font-weight: 600;padding: 4px 0 0 0; word-wrap: break-word;">
                          EMAIL: {{bolData?.brokerEmail}}</div>
                        <!--  -->
                        <!-- <div style="width: 50%;float: left;font-weight: 600;padding: 4px 0 0 0;"> carrier QuoteId : {{BOLd?.carrierQuoteId ?  BOLd?.carrierQuoteId : '--'}}</div> -->
                      </div>
                      <!-- <div *ngIf="!carrier" [ngStyle]="{'margin-bottom': (resultData?.consigneeAddress.extraServices && resultData?.consigneeAddress.extraServices.length > 0) ? '0' : '45px'}"
                                      style="width:100%;float:left; display: flex; justify-content: center; margin-top: 10px;" #carrierLogoContainer id="carrierLogoContainer">
                                      
                                  </div>
                                  <div *ngIf="carrier" style="text-align: center;">
                                      <img style="width: 85px;padding: 3px 0;"
                                          src="{{'data:image/jpeg;base64,'+BOLd?.carrierLogo}}"
                                          alt="{{BOLd?.carrier}}" />
                                  </div>
                                  <div
                                      style="font-size: 10px; font-weight: 600; font-family: poppins, sans-serif; padding: 7px; border-bottom: 1px solid #D4D4D4; line-height: 1.4;">
                                      SHIPPER:
                                      <ng-container
                                          *ngIf="resultData?.shipperAddress.extraServices && resultData?.shipperAddress.extraServices.length > 0; else noServices">
                                          <ng-container
                                              *ngFor="let extraService of resultData?.shipperAddress.extraServices; let last = last">
                                              {{ extraService }}{{ !last ? ',' : '' }}
                                          </ng-container>
                                      </ng-container>
                                      <ng-template #noServices>--</ng-template>
                                  </div>
                                  <div [ngStyle]="{'padding-bottom': (resultData?.consigneeAddress.extraServices && resultData?.consigneeAddress.extraServices.length > 0) ? '0' : '70px'}"
                                      style="font-size: 10px; font-weight: 600; font-family: poppins, sans-serif; padding: 7px; border-bottom: 1px solid #D4D4D4; line-height: 1.4;">
                                      CONSIGNEE:
                                      <ng-container
                                          *ngIf="resultData?.consigneeAddress.extraServices && resultData?.consigneeAddress.extraServices.length > 0; else noServices">
                                          <ng-container
                                              *ngFor="let extraService of resultData?.consigneeAddress.extraServices; let last = last">
                                              {{ extraService }}{{ !last ? ',' : '' }}
                                          </ng-container>
                                      </ng-container>
                                      <ng-template #noServices>--</ng-template>
                                  </div> -->
                      <div
                        style="width:100%;float:left; font-family: poppins,sans-serif; font-weight: 600; font-size: 12px;">
                        <span>CARRIER QUOTEID:</span>
                        {{bolData?.carrierQuoteId}}
                      </div>
                      <div style="width: 100%;float: left; font-size: 12px; font-weight: 500;">
                        <div style="width:100%;float: left;    font-weight: 500; font-family: poppins,sans-serif;">
                          CARRIER SERVICE NAME
                        </div>
                        <div>{{bolData?.carrier}}
                          {{bolData?bolData.carrierServiceName?bolData.carrierServiceName:"":""}}
                        </div>
                      </div>
                      <div style="width: 100%;float: left; font-size: 11px; font-weight: 500;" *ngIf="bolData?.ladingNo">
                        <div style="width:100%;float: left;    font-weight: 500; font-family: poppins,sans-serif;">
                          CARRIER REF NO.: <span><b>{{bolData?.ladingNo ? bolData?.ladingNo : '--'}}</b></span>
                        </div>
                      </div>
                      <div style="width: 100%;float: left; display: flex;">
                        <div
                          style="width: 59%;float: left;padding: 5px 4px 3px 0; font-size: 10px; font-weight: 400; font-family: poppins,sans-serif;">
                          SIGNATURE:
                        </div>
                        <div class="signature_line"></div>
                      </div>
                      <div style="width: 100%;float: left; display: flex;">
                        <div
                          style="width: 59%;float: left;padding: 5px 4px 3px 0; font-size: 10px; font-weight: 400; font-family: poppins,sans-serif;">
                          DATE:
                        </div>
                        <div class="signature_line"></div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%; display: flex;">
                    <div style="width: 62%; border: 1px solid #D4D4D4;">
                      <div class="freight_collect_shipments_box1">
                        <div
                          style="font-size: 10px; font-weight: 600; font-family: poppins,sans-serif; padding-left: 4px;">
                          SHIPPER CERTIFICATION</div>
                      </div>
                      <div class="consignor_signature_box">
                        <div
                          style="font-size: 8px;font-weight: 400; font-family: poppins,sans-serif; line-height: 1.4; padding : 4px !important;">
                          Subject to section 9 of conditions of applicable bill of
                          lading. If this shipment is to be delivered to the
                          consignee,
                          without recourse on the consignor, the consignor shall sign
                          the following statement. The carrier may decline to make
                          delivery of this shipment without payment of freight and all
                          other lawful charges.</div>
                        <div style="width: 65%;float: left;margin-bottom: 10px; margin-top: 13px; padding-left: 4px;">
                          <div
                            style="width:43%;float: left;padding-top: 6px;font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;">
                            SHIPPERS NAME
                          </div>
                          <div style="font-size: 10px;font-weight: 400; font-family: poppins,sans-serif; ">
  
                          </div>
                          <div class="signature_line1" style="margin-top: 18px;">
                          </div>
                        </div>
                        <div style="width: 35%;float: left;">
                          <div style="width:30%;float: left;padding: 0;">
                          </div>
                        </div>
                        <div style="width: 65%;float: left; margin-top: 5px; padding-left: 4px;">
                          <div
                            style="width:43%;float: left;padding: 4px;font-size: 10px;font-weight: 400; font-family: poppins,sans-serif; padding-left: 0px !important; padding-left: 4px;">
                            SIGNATURE
                          </div>
                          <div class="signature_line1" style="margin-top: 18px;">
                          </div>
                        </div>
                        <div style="width: 35%;float: left; margin-top: 10px;">
                          <div
                            style="width:30%;float: left;padding: 0; font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;">
                            DATE
                          </div>
                          <div class="signature_line1"></div>
                        </div>
                      </div>
                    </div>
                    <div style="width: 38%;">
                      <div class="freight_collect_shipments_box"
                        style="width: 100%; background-color: #f7c765; float: left; padding: 0;">
                        <div
                          style="color: #000;font-size: 10px; font-weight: 600; font-family: poppins,sans-serif;  padding: 4px;">
                          CONSIGNEE CERTIFICATION
                        </div>
                      </div>
                      <div class="consignor_signature_box" style="padding-left: 4px;">
                        <div
                          style="font-size: 10px; font-weight: 400; font-family: poppins,sans-serif; padding: 3px; padding-left: 0px;float: left;">
                          Product was received in apparent good order except as noted.
                        </div>
                        <div
                          style="width: 65%;float: left; margin-bottom: 10px;  margin-top: 5px; display: flex; align-items: flex-end;">
                          <div
                            style="width:43%;float: left;padding-top: 4px; font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;">
                            CONSIGNOR NAME
                          </div>
                          <div style="font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;  ">
  
                          </div>
                          <div class="signature_line1" style="margin-top: 17px;">
                          </div>
                        </div>
                        <div style="width: 35%;float: left;">
                          <div style="width:30%;float: left;padding: 0;">
                          </div>
                        </div>
                        <div style="width: 65%;float: left; margin-top: 10px;">
                          <div
                            style="width:43%;float: left;padding: 0; font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;">
                            SIGNATURE
                          </div>
                          <div class="signature_line1" style="margin-top: 10px;">
                          </div>
                        </div>
                        <div style="width: 35%;float: left; margin-top: 10px;">
                          <div
                            style="width:30%;float: left;padding: 0; font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;">
                            DATE
                          </div>
                          <div class="signature_line1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-bottom: 0px !important; border: 1px solid transparent;">
                <div class="col-7">
                  <div><strong>{{bolData.carrierBolDisplayAddress?bolData.carrierBolDisplayAddress:'1033 JAYSON COURT, MISSISSAUGA, ON L4W 2P4, CANADA'}}</strong>
                  </div>
                  <div style="font-size: 10px;"><span style="color:#FF8D00;font-weight: 600;">www.shipcarte.com</span>
                    <span style="margin-left: 10px;">888.424.8878</span>
                  </div>
                </div>
                
              </div>
              <span class="break-page"></span>
              <div class="bol-page_section second-sec"
                style="  background: #fff;padding: 0px 5px;height: auto;border: 1px solid #ccc;float: left; font-family: poppins,sans-serif;margin-top: 40px;">
                <div class="text-center" style="background-color: #F3F5F7;">
                  <h3 class="m-0" style="font-size: 12px !important;margin-bottom:0 !important">UNIFORM
                    STRAIGHT BILL OF LADING</h3>
                  <h5 class="mb-0"><b>Terms & Conditions</b></h5>
  
                </div>
                <div class="row">
                  <div class="col-12">
                    <div>
                      <h5 class="m-0"><b>1. UNIFORM TERMS OF CARRIAGE TO APPLY</b></h5>
                      <p>
                        This contract for carriage of goods includes all uniform terms
                        of carriage enacted for the carriage
                        of general freight pursuant to any statute, regulation or by any
                        lawful authority, which is in force and effect
                        in the jurisdiction of origin of this contract at the time of
                        shipment.
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>2. NOTICE OF CLAIM</b></h5>
                      <p>
                        (1) No carrier is liable for loss, damage or delay to any goods
                        carried under the Bill of Lading unless notice
                        thereof setting out the particulars of the origin, destination
                        and date of shipment of the goods and the
                        estimated amount claimed in respect of such loss, damage or
                        delay is given in writing to the originating
                        carrier or to the delivering carrier within Sixty (60) days
                        after delivery of the goods, or in the case of failure
                        to make delivery, within Nine (9) months of the date of
                        shipment. (2) The final statement of claim must be filed
                        within Nine (9) months from the date of shipment together with a
                        copy of the paid freight bill.
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>3. RECEIPT OF GOODS</b></h5>
                      <p>
                        The carrier acknowledges receipt of the goods itemized on the
                        face of the within Bill of Lading by the consignor in
                        apparent good order and condition.
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>4. CONSIGNOR’S WARRANTIES AS TO PREPARATION OF
                          SHIPMENT</b></h5>
                      <p>
                        The consignor warrants to the carrier:
                        (1) The cartons, containers and goods have been marked to
                        identify the consignee, the consignee’s address, number of
                        pieces
                        and any delivery instructions and that such markings are
                        consistent with the markings and instructions of this Bill of
                        Lading.
                        (2) The goods have been properly packaged and prepared to
                        withstand those risks of damage necessarily incidental to
                        transportation.
                        (3) If these goods are dangerous goods, the goods and this bill
                        of lading havebeen prepared to comply with all federal and
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>5. LIMITATION OF LIABILITY</b></h5>
                      <p>
                        (1) Unless the consignor has declared a value of the goods on
                        the face of the Bill of Lading, the amount of any loss or damage
                        for which
                        the carrier is liable, whether or not such loss or damage
                        results from negligence, shall be the lesser of: a) the value of
                        the goods at the
                        place and time of shipment, including freight and other charges
                        if paid, and b) $4.41 per kilogram computed on the total weight
                        of the shipment.
                        (2) The parties agree that the carrier cannot reasonably be
                        aware of the consequences of and the costs accruing to
                        the consignor, consignee, owner or any other party in the event
                        of the loss of use of the goods due to the late,
                        delayed or non-delivery of the goods, or the whole or partial
                        loss or destruction of all or any part of the goods
                        however caused. Accordingly, the carrier is not liable for the
                        indirect, consequential or incidental loss occurring
                        to any party because of late, delayed or non-delivery of, loss
                        of or damage to the goods. (3) If the consignor has
                        declared a value of the goods on the face of the contract of
                        carriage, the amount of any loss or damage for which the
                        carrier is liable shall not exceed the declared value.
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>6. EXCEPTIONS FROM LIABILITY</b></h5>
                      <p>
                        The carrier shall not be liable for loss, damage or delay to any
                        of the goods described in the contract of carriage caused by an
                        act of God, the Queen’s or public enemies, riots, strikes, a
                        defect or inherent vice in the goods, an act or default of the
                        consignor, owner or consignee, authority of law, quarantine or
                        difference in weights of commodities caused by natural
                        shrinkage.
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>7. DELAY</b></h5>
                      <p>
                        No carrier is bound to carry goods by any particular public
                        truck or in time for any
                        particular market or otherwise than with due dispatch, unless by
                        agreement that is
                        specifically endorsed in the contract of carriage and signed by
                        the parties.
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>8. DANGEROUS GOODS</b></h5>
                      <p>
                        Every person, whether as principal or agent, shipping dangerous
                        goods without previous full disclosure to the carrier as
                        required by law shall indemnify the carrier against all loss,
                        damage or delay caused by the failure to disclose, and such
                        goods may be warehoused at the consignor’s sole risk andexpense.
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>9. LIABILITY FOR PAYMENT</b></h5>
                      <p>
                        Regardless of any instructions provided for the payment of
                        freight charges, the consignor shall, in the event the carrier
                        is unable to collect in accordance with those instructions, be
                        responsible for all freight charges together with all costs
                        incurred as a result of inability to collect transportation
                        charges in accordance with the consignor’s instructions.
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>10. LANGUAGE</b></h5>
                      <p>
                        The parties hereby confirm their express wish that this contract
                        and all related documents be prepared in the English language
                        only. Les parties reconnaissent avoir exigé que le présente
                        contrat et tous les documents connexes soient rédigés en anglais
                        seulement.
                      </p>
                    </div>
                    <div>
                      <h5 class="m-0"><b>11. ENTIRE CONTRACT</b></h5>
                      <p>
                        The uniform terms of carriage and conditions herein form the
                        entire contract between the parties, which shall not be modified
                        without the written consent of both parties.
                      </p>
                    </div>
  
                    <div>
                      <img style="width: 100px; padding-top: 12px;" src={{bolData?.shipcarteLogoSmall}} alt="" />
                      <div>
                        ORDER NO.<span
                          style="color:#FF8D00; font-size: 12px !important; font-weight: 400;">{{bolData?.orderNumber}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="border:1px solid transparent;">
                <div class="col-7">
                  <div><strong>{{bolData.carrierBolDisplayAddress?bolData.carrierBolDisplayAddress:'1033 JAYSON COURT, MISSISSAUGA, ON L4W 2P4, CANADA'}}</strong>
                  </div>
                  <div><span style="color:#FF8D00;font-weight: 600;">www.shipcarte.com</span>
                    <span style="margin-left: 10px;">888.424.8878</span>
                  </div>
                </div>
                <div class="col-5 text-end" style="display: flex; justify-content: end;">
                  <a style="margin-right: 8px;" href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                    <img src="assets/img/shipment/Black-Linkedin.svg" width="17px" class="m-1 text-center">
                  </a>
                  <a style="margin-right: 8px;" href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                    <img src="assets/img/shipment/Black-Insta.svg" width="17px" class="m-1 text-center">
                  </a>
                  <a style="margin-right: 8px;" href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                    <img src="assets/img/shipment/Black-Twitter.svg" width="17px" class="m-1 text-center">
                  </a>
                  <a style="margin-right: 8px;" href="https://www.facebook.com/ShipCarte/" target="_blank">
                    <img src="assets/img/shipment/Black-Facebook.svg" width="17px" class="m-1 text-center">
                  </a>
                </div>
              </div>
              <span *ngIf="bolDataList.length!==1 && i!== bolDataList.length - 1" class="break-page"></span>
            </div>
          </div>
          </div>
        </body>
  
        </html>
      </div>
    </div>
  </div>